import song00 from './score/song00.jpg'
import song01 from './score/song01.jpg'
import song02 from './score/song02.jpg'
import song03 from './score/song03.jpg'
import song04 from './score/song04.jpg'
import song05 from './score/song05.jpg'
import song06 from './score/song06.jpg'
import song07 from './score/song07.jpg'
import song08 from './score/song08.jpg'
import song09 from './score/song09.jpg'
import song10 from './score/song10.jpg'
import song11 from './score/song11.jpg'
import song12 from './score/song12.jpg'
import song13 from './score/song13.jpg'
import song14 from './score/song14.jpg'
import song15 from './score/song15.jpg'
import song16 from './score/song16.jpg'
import song17 from './score/song17.jpg'
import song18 from './score/song18.jpg'
import song19 from './score/song19.jpg'
import song20 from './score/song20.jpg'

const songdata00 =
{ id: 0,
  title: "코드 변경 참고",
  rootkey: "C",
  artist: "-",
  tempo: "-",
  score: song00,
  chordArray: [
    { pos: { left: "20px", top: "20px" }, chord: '1 :' },     { pos: { left: "150px", top: "20px" }, chord: '1' },
    { pos: { left: "20px", top: "40px" }, chord: '2b :' },     { pos: { left: "150px", top: "40px" }, chord: '2b' },
    { pos: { left: "20px", top: "60px" }, chord: '2 :' },     { pos: { left: "150px", top: "60px" }, chord: '2' },
    { pos: { left: "20px", top: "80px" }, chord: '3b :' },     { pos: { left: "150px", top: "80px" }, chord: '3b' },
    { pos: { left: "20px", top: "100px" }, chord: '3 :' },     { pos: { left: "150px", top: "100px" }, chord: '3' },
    { pos: { left: "20px", top: "120px" }, chord: '4 :' },     { pos: { left: "150px", top: "120px" }, chord: '4' },
    { pos: { left: "20px", top: "140px" }, chord: '5 :' },     { pos: { left: "150px", top: "140px" }, chord: '5' },
    { pos: { left: "20px", top: "160px" }, chord: '5/4 :' },     { pos: { left: "150px", top: "160px" }, chord: '5/4' },
    { pos: { left: "20px", top: "180px" }, chord: '5dom7 :' },     { pos: { left: "150px", top: "180px" }, chord: '5dom7' },
    { pos: { left: "20px", top: "200px" }, chord: '5m :' },     { pos: { left: "150px", top: "200px" }, chord: '5m' },
    { pos: { left: "20px", top: "220px" }, chord: '6b :' },     { pos: { left: "150px", top: "220px" }, chord: '6b' },
    { pos: { left: "20px", top: "240px" }, chord: '6 :' },     { pos: { left: "150px", top: "240px" }, chord: '6' },
    { pos: { left: "20px", top: "260px" }, chord: '7b :' },     { pos: { left: "150px", top: "260px" }, chord: '7b' },
    { pos: { left: "20px", top: "280px" }, chord: '7bM7 :' },     { pos: { left: "150px", top: "280px" }, chord: '7bM7' },
    { pos: { left: "20px", top: "300px" }, chord: '7 :' },     { pos: { left: "150px", top: "300px" }, chord: '7' },
    { pos: { left: "20px", top: "320px" }, chord: '4/1 :' },     { pos: { left: "150px", top: "320px" }, chord: '4/1' },
    { pos: { left: "20px", top: "340px" }, chord: '4/6 :' },     { pos: { left: "150px", top: "340px" }, chord: '4/6' },
    { pos: { left: "20px", top: "360px" }, chord: '5/1 :' },     { pos: { left: "150px", top: "360px" }, chord: '5/1' },
    { pos: { left: "20px", top: "380px" }, chord: '1/5 :' },     { pos: { left: "150px", top: "380px" }, chord: '1/5' },
    { pos: { left: "20px", top: "400px" }, chord: '7b/1 :' },     { pos: { left: "150px", top: "400px" }, chord: '7b/1' },
    { pos: { left: "20px", top: "420px" }, chord: '3m :' },     { pos: { left: "150px", top: "420px" }, chord: '3m' },
    { pos: { left: "20px", top: "440px" }, chord: '3dom7 :' },     { pos: { left: "150px", top: "440px" }, chord: '3dom7' },
    { pos: { left: "20px", top: "460px" }, chord: '4/5 :' },     { pos: { left: "150px", top: "460px" }, chord: '4/5' },
    { pos: { left: "20px", top: "480px" }, chord: '5b :' },     { pos: { left: "150px", top: "480px" }, chord: '5b' },
    { pos: { left: "20px", top: "500px" }, chord: '5bm7b5 :' },     { pos: { left: "150px", top: "500px" }, chord: '5bm7b5' },
    { pos: { left: "20px", top: "520px" }, chord: '4m :' },     { pos: { left: "150px", top: "520px" }, chord: '4m' },
    { pos: { left: "20px", top: "540px" }, chord: '4m/1 :' },     { pos: { left: "150px", top: "540px" }, chord: '4m/1' },


  ]
}
const songdata01 =
    {
      id: 1,
      title: "꽃들도",
      rootkey: "G",
      artist: "",
      tempo: "63, (4/4)",
      score: song01,
      chordArray: [
        { pos: { left: "78px", top: "54px" }, chord: 1 },
        { pos: { left: "171px", top: "50px" }, chord: 5 },
        { pos: { left: "308px", top: "50px" }, chord: 4 },
        { pos: { left: "434px", top: "50px" }, chord: 4 },

        { pos: { left: "55px", top: "174px" }, chord: 6 },
        { pos: { left: "144px", top: "174px" }, chord: 5 },
        { pos: { left: "284px", top: "174px" }, chord: 4 },
        { pos: { left: "421px", top: "174px" }, chord: 4 },

        { pos: { left: "154px", top: "298px" }, chord: 4 },
        { pos: { left: "233px", top: "298px" }, chord: 5 },
        { pos: { left: "300px", top: "298px" }, chord: 1 },
        { pos: { left: "364px", top: "298px" }, chord: 7 },
        { pos: { left: "411px", top: "298px" }, chord: 6 },

        { pos: { left: "53px", top: "393px" }, chord: 4 },
        { pos: { left: "148px", top: "393px" }, chord: 5 },
        { pos: { left: "223px", top: "393px" }, chord: 1 },
        { pos: { left: "337px", top: "375px" }, chord: "*" },
        { pos: { left: "342px", top: "375px" }, chord: "5bm7b5" },
        { pos: { left: "342px", top: "393px" }, chord: 4 },
        { pos: { left: "422px", top: "375px" }, chord: "*" },
        { pos: { left: "426px", top: "375px" }, chord: "4m" },
        { pos: { left: "426px", top: "393px" }, chord: 5 },

        { pos: { left: "52px", top: "495px" }, chord: 1 },
        { pos: { left: "48px", top: "480px" }, chord: "*" },
        { pos: { left: "52px", top: "480px" }, chord: "3" },
        { pos: { left: "113px", top: "495px" }, chord: 7 },
        { pos: { left: "160px", top: "495px" }, chord: 6 },
        { pos: { left: "243px", top: "495px" }, chord: 4 },
        { pos: { left: "340px", top: "495px" }, chord: 5 },
        { pos: { left: "427px", top: "495px" }, chord: 1 },

      ]
}
const songdata02 =
{
  id: 2,
  title: "임재",
  rootkey: "A",
  artist: "",
  tempo: "60, (4/4)",
  score: song02,
  chordArray: [
    { pos: { left: "15px", top: "15px" }, chord: 1 },
  ]
}
const songdata03 =
{
  id: 3,
  title: "은혜",
  rootkey: "A",
  artist: "-",
  tempo: "60, (4/4)",
  score: song03,
  chordArray: [
    { pos: { left: "15px", top: "15px" }, chord: 1 },
    { pos: { left: "15px", top: "15px" }, chord: 4 },
  ]
}
const songdata04 =
{
  id: 4,
  title: "주예수 내맘에 들어와",
  rootkey: "A",
  artist: "??",
  tempo: "60, (4/4)",
  score: song04,
  chordArray: [
    { pos: { left: "15px", top: "15px" }, chord: 1 },
  ]
}
const songdata05 =
{
  id: 5,
  title: "구주예수 의지함이",
  rootkey: "A",
  artist: "??",
  tempo: "60, (4/4)",
  score: song05,
  chordArray: [
    { pos: { left: "15px", top: "15px" }, chord: 1 },
  ]
}
const songdata06 =
{
  id: 6,
  title: "나의 영혼이",
  rootkey: "A",
  artist: "??",
  tempo: "60, (4/4)",
  score: song06,
  chordArray: [
    { pos: { left: "15px", top: "15px" }, chord: 1 },
  ]
}
const songdata07 =
{
  id: 7,
  title: "기뻐하며 왕께 노래 부르리",
  rootkey: "G",
  artist: "어노인팅",
  tempo: "130, (4/4)",
  score: song07,
  chordArray: [
    { pos: { left: "120px", top: "58px" }, chord: "3" },
    { pos: { left: "200px", top: "58px" }, chord: "4" },
    { pos: { left: "314px", top: "58px" }, chord: "6" },
    { pos: { left: "430px", top: "58px" }, chord: "5" },

    { pos: { left: "100px", top: "141px" }, chord: "3" },
    { pos: { left: "200px", top: "141px" }, chord: "4" },
    { pos: { left: "314px", top: "141px" }, chord: "6" },
    { pos: { left: "430px", top: "141px" }, chord: "5" },

    { pos: { left: "100px", top: "230px" }, chord: "6" },
    { pos: { left: "150px", top: "230px" }, chord: "7" },
    { pos: { left: "200px", top: "230px" }, chord: "1" },
    { pos: { left: "314px", top: "230px" }, chord: "2" },
    { pos: { left: "350px", top: "230px" }, chord: "3" },
    { pos: { left: "430px", top: "230px" }, chord: "4" },

    { pos: { left: "45px", top: "312px" }, chord: "5" },
    { pos: { left: "100px", top: "312px" }, chord: "3" },
    { pos: { left: "240px", top: "312px" }, chord: "2" },
    { pos: { left: "360px", top: "312px" }, chord: "1" },

    { pos: { left: "120px", top: "400px" }, chord: "4" },
    { pos: { left: "230px", top: "400px" }, chord: "5" },
    { pos: { left: "350px", top: "400px" }, chord: "1" },
    { pos: { left: "450px", top: "400px" }, chord: "6" },

    { pos: { left: "120px", top: "488px" }, chord: "4" },
    { pos: { left: "250px", top: "488px" }, chord: "5" },
    { pos: { left: "350px", top: "488px" }, chord: "1" },
    { pos: { left: "450px", top: "488px" }, chord: "6" },

    { pos: { left: "120px", top: "573px" }, chord: "4" },
    { pos: { left: "200px", top: "573px" }, chord: "5" },
    { pos: { left: "320px", top: "573px" }, chord: "1" },
    { pos: { left: "430px", top: "573px" }, chord: "6" },

    { pos: { left: "100px", top: "657px" }, chord: "4" },
    { pos: { left: "200px", top: "657px" }, chord: "5" },
    { pos: { left: "320px", top: "657px" }, chord: "1" },
  ]
}
const songdata08 =
{
  id: 8,
  title: "예수 따라가며",
  rootkey: "E",
  artist: "어노인팅",
  tempo: "78, (3/4)",
  score: song08,
  chordArray: [
    { pos: { left: "100px", top: "74px" }, chord: "1" },
    { pos: { left: "250px", top: "74px" }, chord: "5/1" },
    { pos: { left: "330px", top: "74px" }, chord: "1" },
    { pos: { left: "410px", top: "74px" }, chord: "4/1" },

    { pos: { left: "130px", top: "241px" }, chord: "5/1" },
    { pos: { left: "239px", top: "241px" }, chord: "1" },
    { pos: { left: "400px", top: "241px" }, chord: "5/1" },

    { pos: { left: "50px", top: "409px" }, chord: "1" },
    { pos: { left: "120px", top: "409px" }, chord: "4/6" },
    { pos: { left: "200px", top: "409px" }, chord: "5" },
    { pos: { left: "270px", top: "409px" }, chord: "1" },
    { pos: { left: "320px", top: "409px" }, chord: "7" },
    { pos: { left: "400px", top: "409px" }, chord: "1" },

    { pos: { left: "50px", top: "574px" }, chord: "2b" },
    { pos: { left: "120px", top: "574px" }, chord: "2" },
    { pos: { left: "200px", top: "574px" }, chord: "7" },
    { pos: { left: "280px", top: "574px" }, chord: "3" },
    { pos: { left: "400px", top: "574px" }, chord: "5" },
    { pos: { left: "370px", top: "574px" }, chord: "4" },
    { pos: { left: "440px", top: "574px" }, chord: "1" },
  ]
}
const songdata09 =
{
  id: 9,
  title: "나의 가장 낮은 마음",
  rootkey: "G",
  artist: "쉐키나",
  tempo: "130, (4/4)",
  score: song09,
  chordArray: [
    { pos: { left: "75px", top: "54px" }, chord: "1" },
    { pos: { left: "180px", top: "54px" }, chord: "1" },
    { pos: { left: "280px", top: "54px" }, chord: "4" },
    { pos: { left: "390px", top: "54px" }, chord: "4" },

    { pos: { left: "50px", top: "141px" }, chord: "6" },
    { pos: { left: "165px", top: "141px" }, chord: "6" },
    { pos: { left: "270px", top: "141px" }, chord: "4" },
    { pos: { left: "400px", top: "141px" }, chord: "4" },

    { pos: { left: "50px", top: "239px" }, chord: "4" },
    { pos: { left: "150px", top: "239px" }, chord: "5" },
    { pos: { left: "240px", top: "239px" }, chord: "3" },
    { pos: { left: "330px", top: "239px" }, chord: "6" },
    { pos: { left: "390px", top: "239px" }, chord: "4" },

    { pos: { left: "50px", top: "314px" }, chord: "5" },
    { pos: { left: "120px", top: "314px" }, chord: "6" },
    { pos: { left: "240px", top: "314px" }, chord: "4" },
    { pos: { left: "330px", top: "314px" }, chord: "5" },
    { pos: { left: "410px", top: "314px" }, chord: "6b" },

    { pos: { left: "45px", top: "394px" }, chord: "6" },
    { pos: { left: "120px", top: "394px" }, chord: "7b" },
    { pos: { left: "240px", top: "394px" }, chord: "4/6" },
    { pos: { left: "360px", top: "394px" }, chord: "5" },
    { pos: { left: "360px", top: "420px" }, chord: "^ 땡기기" },

    { pos: { left: "45px", top: "471px" }, chord: "1" },
    { pos: { left: "120px", top: "471px" }, chord: "2" },
    { pos: { left: "230px", top: "471px" }, chord: "4" },
    { pos: { left: "310px", top: "471px" }, chord: "5" },
    { pos: { left: "415px", top: "471px" }, chord: "3" },

    { pos: { left: "50px", top: "545px" }, chord: "6" },
    { pos: { left: "170px", top: "545px" }, chord: "4" },
    { pos: { left: "270px", top: "545px" }, chord: "5/4" },
    { pos: { left: "350px", top: "545px" }, chord: "1" },
  ]
}
const songdata10 =
{
  id: 10,
  title: "내 주 되신 주를 참 사랑하고",
  rootkey: "D",
  artist: "아이자야",
  tempo: "65, (4/4)",
  score: song10,
  chordArray: [
    { pos: { left: "100px", top: "55px" }, chord: "1" },
    { pos: { left: "150px", top: "55px" }, chord: "2" },
    { pos: { left: "200px", top: "55px" }, chord: "3" },
    { pos: { left: "250px", top: "55px" }, chord: "6" },
    { pos: { left: "330px", top: "55px" }, chord: "2" },
    { pos: { left: "380px", top: "55px" }, chord: "5" },
    { pos: { left: "440px", top: "55px" }, chord: "1" },

    { pos: { left: "90px", top: "217px" }, chord: "1" },
    { pos: { left: "134px", top: "217px" }, chord: "2" },
    { pos: { left: "200px", top: "217px" }, chord: "3" },
    { pos: { left: "262px", top: "217px" }, chord: "6" },
    { pos: { left: "327px", top: "217px" }, chord: "2" },
    { pos: { left: "373px", top: "217px" }, chord: "5" },
    { pos: { left: "433px", top: "217px" }, chord: "1" },

    { pos: { left: "433px", top: "100px" }, chord: "3" },

    { pos: { left: "433px", top: "100px" }, chord: "3" },
  
  ]
}
const songdata11 =
{
  id: 11,
  title: "예수 사랑하심을",
  rootkey: "E",
  artist: "쉐키나, 제이어스 편곡",
  tempo: "130, (4/4)",
  score: song11,
  chordArray: [
    { pos: { left: "75px", top: "54px" }, chord: "1" },

  ]
} 
const songdata12 =
{
  id: 12,
  title: "주 안에 있는 나에게",
  rootkey: "G",
  artist: "쉐키나",
  tempo: "50, (6/8)",
  score: song12,
  chordArray: [
    { pos: { left: "55px", top: "71px" }, chord: "1" },
    { pos: { left: "151px", top: "71px" }, chord: "1" },
    { pos: { left: "252px", top: "71px" }, chord: "1" },
  ]
}
const songdata13 =
{
  id: 13,
  title: "은혜로다",
  rootkey: "A",
  artist: "쉐키나",
  tempo: "50, (6/8)",
  score: song13,
  chordArray: [
    { pos: { left: "145px", top: "88px" }, chord: "4" },
    { pos: { left: "212px", top: "88px" }, chord: "5" },
    { pos: { left: "370px", top: "88px" }, chord: "1" },

    { pos: { left: "187px", top: "165px" }, chord: "4" },
    { pos: { left: "284px", top: "165px" }, chord: "5" },
    { pos: { left: "400px", top: "165px" }, chord: "1" },

    { pos: { left: "163px", top: "244px" }, chord: "4" },
    { pos: { left: "230px", top: "244px" }, chord: "5" },
    { pos: { left: "368px", top: "244px" }, chord: "1" },

    { pos: { left: "191px", top: "321px" }, chord: "4" },
    { pos: { left: "301px", top: "321px" }, chord: "5" },
    { pos: { left: "406px", top: "321px" }, chord: "1" },

    { pos: { left: "191px", top: "395px" }, chord: "4" },
    { pos: { left: "263px", top: "395px" }, chord: "5" },
    { pos: { left: "398px", top: "395px" }, chord: "1" },

    { pos: { left: "191px", top: "475px" }, chord: "4" },
    { pos: { left: "263px", top: "475px" }, chord: "5" },
    { pos: { left: "398px", top: "475px" }, chord: "1" },

    { pos: { left: "72px", top: "535px" }, chord: "section" },
    { pos: { left: "72px", top: "553px" }, chord: "*" },
    { pos: { left: "77px", top: "553px" }, chord: "5m" },
    { pos: { left: "137px", top: "553px" }, chord: "1" },
    { pos: { left: "191px", top: "553px" }, chord: "4" },
    { pos: { left: "263px", top: "553px" }, chord: "5" },
    { pos: { left: "398px", top: "553px" }, chord: "1" },
    { pos: { left: "445px", top: "553px" }, chord: "7" },

    { pos: { left: "70px", top: "630px" }, chord: "6" },
    { pos: { left: "154px", top: "630px" }, chord: "2" },
    { pos: { left: "259px", top: "630px" }, chord: "5" },
    { pos: { left: "374px", top: "630px" }, chord: "1" },

  ]
}
const songdata14 =
{
  id: 14,
  title: "빈들에 마른 풀 같이",
  rootkey: "A",
  artist: "찬송가 편곡",
  tempo: "50, (6/8)",
  score: song14,
  chordArray: [
    { pos: { left: "75px", top: "71px" }, chord: "1" },
    { pos: { left: "121px", top: "71px" }, chord: "7" },
    { pos: { left: "183px", top: "71px" }, chord: "6" },
    { pos: { left: "233px", top: "71px" }, chord: "1/5" },
    { pos: { left: "287px", top: "71px" }, chord: "5" },
    { pos: { left: "336px", top: "71px" }, chord: "5/4" },
    { pos: { left: "409px", top: "71px" }, chord: "3" },

    { pos: { left: "70px", top: "200px" }, chord: "4" },
    { pos: { left: "167px", top: "200px" }, chord: "3" },
    { pos: { left: "275px", top: "200px" }, chord: "2" },
    { pos: { left: "386px", top: "200px" }, chord: "5" },

    { pos: { left: "62px", top: "332px" }, chord: "3" },
    { pos: { left: "171px", top: "332px" }, chord: "4" },
    { pos: { left: "276px", top: "332px" }, chord: "6" },
    { pos: { left: "386px", top: "332px" }, chord: "5" },

    { pos: { left: "63px", top: "410px" }, chord: "6" },
    { pos: { left: "112px", top: "410px" }, chord: "4" },
    { pos: { left: "167px", top: "410px" }, chord: "3" },
    { pos: { left: "215px", top: "410px" }, chord: "6" },
    { pos: { left: "256px", top: "410px" }, chord: "5" },
    { pos: { left: "368px", top: "410px" }, chord: "1" },

    { pos: { left: "180px", top: "507px" }, chord: "4" },
    { pos: { left: "286px", top: "507px" }, chord: "3" },
    { pos: { left: "385px", top: "507px" }, chord: "4" },
    { pos: { left: "431px", top: "507px" }, chord: "5" },

    { pos: { left: "72px", top: "595px" }, chord: "1" },
    { pos: { left: "152px", top: "595px" }, chord: "6" },
    { pos: { left: "237px", top: "595px" }, chord: "3" },
    { pos: { left: "319px", top: "595px" }, chord: "4" },
    { pos: { left: "364px", top: "595px" }, chord: "5" },
    { pos: { left: "408px", top: "595px" }, chord: "1" },
  ]
}
const songdata15 =
{
  id: 15,
  title: "내 영혼이 그윽히 깊은 데서",
  rootkey: "A",
  artist: "아이자야",
  tempo: "69, (3/4)",
  score: song15,
  chordArray: [
    { pos: { left: "141px", top: "98px" }, chord: "3" },
    { pos: { left: "221px", top: "98px" }, chord: "4" },
    { pos: { left: "317px", top: "98px" }, chord: "3" },
    { pos: { left: "421px", top: "98px" }, chord: "4" },

    { pos: { left: "141px", top: "260px" }, chord: "3" },
    { pos: { left: "221px", top: "260px" }, chord: "4" },
    { pos: { left: "317px", top: "260px" }, chord: "5" },
    { pos: { left: "421px", top: "260px" }, chord: "1" },

    { pos: { left: "74px", top: "418px" }, chord: "1" },
    { pos: { left: "120px", top: "418px" }, chord: "3" },
    { pos: { left: "158px", top: "418px" }, chord: "4" },
    { pos: { left: "218px", top: "418px" }, chord: "1" },
    { pos: { left: "245px", top: "418px" }, chord: "7" },
    { pos: { left: "280px", top: "418px" }, chord: "6" },
    { pos: { left: "339px", top: "418px" }, chord: "5b" },
    { pos: { left: "400px", top: "418px" }, chord: "5" },

    { pos: { left: "116px", top: "532px" }, chord: "1" },
    { pos: { left: "158px", top: "532px" }, chord: "3" },
    { pos: { left: "230px", top: "532px" }, chord: "4" },
    { pos: { left: "310px", top: "532px" }, chord: "4/5" },
    { pos: { left: "362px", top: "532px" }, chord: "5" },
    { pos: { left: "412px", top: "532px" }, chord: "1" },
  ]
}
const songdata16 =
{
  id: 16,
  title: "말씀하시면",
  rootkey: "D",
  artist: "쉐키나편곡",
  tempo: "67, (4/4)",
  score: song16,
  chordArray: [
    { pos: { left: "100px", top: "65px" }, chord: "1" },
    { pos: { left: "213px", top: "65px" }, chord: "4/1" },
    { pos: { left: "314px", top: "65px" }, chord: "5/1" },
    { pos: { left: "410px", top: "65px" }, chord: "1" },

    { pos: { left: "51px", top: "140px" }, chord: "3" },
    { pos: { left: "174px", top: "140px" }, chord: "4" },
    { pos: { left: "276px", top: "140px" }, chord: "5" },
    { pos: { left: "403px", top: "140px" }, chord: "1" },

    { pos: { left: "50px", top: "212px" }, chord: "3" },
    { pos: { left: "174px", top: "212px" }, chord: "4" },
    { pos: { left: "280px", top: "212px" }, chord: "5" },
    { pos: { left: "410px", top: "212px" }, chord: "6" },

    { pos: { left: "52px", top: "282px" }, chord: "4" },
    { pos: { left: "145px", top: "282px" }, chord: "4/5" },
    { pos: { left: "251px", top: "282px" }, chord: "1" },
    { pos: { left: "454px", top: "282px" }, chord: "3" },

    { pos: { left: "54px", top: "352px" }, chord: "4" },
    { pos: { left: "173px", top: "352px" }, chord: "3" },
    { pos: { left: "263px", top: "352px" }, chord: "2" },
    { pos: { left: "409px", top: "352px" }, chord: "3" },

    { pos: { left: "50px", top: "422px" }, chord: "4" },
    { pos: { left: "164px", top: "422px" }, chord: "3" },
    { pos: { left: "255px", top: "422px" }, chord: "2" },
    { pos: { left: "405px", top: "422px" }, chord: "6" },

    { pos: { left: "53px", top: "491px" }, chord: "4" },
    { pos: { left: "166px", top: "491px" }, chord: "3" },
    { pos: { left: "258px", top: "491px" }, chord: "2" },
    { pos: { left: "407px", top: "491px" }, chord: "5/1" },

    { pos: { left: "56px", top: "565px" }, chord: "7b" },
    { pos: { left: "185px", top: "565px" }, chord: "4/6" },
    { pos: { left: "329px", top: "565px" }, chord: "5" },
    { pos: { left: "461px", top: "565px" }, chord: "3" },

    { pos: { left: "148px", top: "637px" }, chord: "2" },
    { pos: { left: "243px", top: "637px" }, chord: "5" },
    { pos: { left: "348px", top: "637px" }, chord: "1" },

  ]
}

const songdata17 =
{
  id: 17,
  title: "마지막 날에",
  rootkey: "G",
  artist: "-",
  tempo: "130, (4/4)",
  score: song17,
  chordArray: [
    { pos: { left: "67px", top: "49px" }, chord: "1" },
    { pos: { left: "202px", top: "49px" }, chord: "6" },
    { pos: { left: "281px", top: "49px" }, chord: "4" },
    { pos: { left: "406px", top: "49px" }, chord: "5" },

    { pos: { left: "50px", top: "137px" }, chord: "1" },
    { pos: { left: "185px", top: "137px" }, chord: "6" },
    { pos: { left: "284px", top: "137px" }, chord: "4" },
    { pos: { left: "413px", top: "137px" }, chord: "5" },

    { pos: { left: "50px", top: "224px" }, chord: "1" },
    { pos: { left: "284px", top: "224px" }, chord: "6" },

    { pos: { left: "50px", top: "307px" }, chord: "4" },
    { pos: { left: "45px", top: "295px" }, chord: "*" },
    { pos: { left: "50px", top: "295px" }, chord: "7b" },
    { pos: { left: "136px", top: "307px" }, chord: "2" },
    { pos: { left: "130px", top: "295px" }, chord: "*" },
    { pos: { left: "136px", top: "295px" }, chord: "4/6" },
    { pos: { left: "255px", top: "307px" }, chord: "5" },

    { pos: { left: "69px", top: "395px" }, chord: "1" },
    { pos: { left: "183px", top: "395px" }, chord: "6" },
    { pos: { left: "284px", top: "395px" }, chord: "4" },
    { pos: { left: "411px", top: "395px" }, chord: "5" },

    { pos: { left: "63px", top: "482px" }, chord: "1" },
    { pos: { left: "159px", top: "482px" }, chord: "6" },
    { pos: { left: "268px", top: "482px" }, chord: "4" },
    { pos: { left: "331px", top: "482px" }, chord: "5" },
    { pos: { left: "415px", top: "482px" }, chord: "1" },
  ]
}

const songdata18 =
{
  id: 18,
  title: "사람을 보며 세상을 볼 땐",
  rootkey: "E",
  artist: "-",
  tempo: "120, (4/4)",
  score: song18,
  chordArray: [
    { pos: { left: "88px", top: "52px" }, chord: "1" },
    { pos: { left: "196px", top: "52px" }, chord: "4/1" },
    { pos: { left: "308px", top: "52px" }, chord: "5/1" },
    { pos: { left: "439px", top: "52px" }, chord: "4/1" },
    { pos: { left: "71px", top: "141px" }, chord: "3" },
    { pos: { left: "182px", top: "141px" }, chord: "4" },
    { pos: { left: "306px", top: "141px" }, chord: "5" },
    { pos: { left: "445px", top: "141px" }, chord: "1" },

    { pos: { left: "65px", top: "233px" }, chord: "1" },
    { pos: { left: "165px", top: "233px" }, chord: "3" },
    { pos: { left: "290px", top: "233px" }, chord: "4/6" },
    { pos: { left: "390px", top: "233px" }, chord: "7" },

    { pos: { left: "68px", top: "323px" }, chord: "3" },
    { pos: { left: "177px", top: "323px" }, chord: "4" },
    { pos: { left: "297px", top: "323px" }, chord: "5" },
    { pos: { left: "441px", top: "323px" }, chord: "6" },

    { pos: { left: "68px", top: "408px" }, chord: "3" },
    { pos: { left: "177px", top: "408px" }, chord: "4" },
    { pos: { left: "297px", top: "408px" }, chord: "5" },
    { pos: { left: "441px", top: "408px" }, chord: "1" },

    { pos: { left: "71px", top: "499px" }, chord: "1" },
    { pos: { left: "179px", top: "499px" }, chord: "3" },
    { pos: { left: "284px", top: "499px" }, chord: "4/6" },
    { pos: { left: "400px", top: "499px" }, chord: "7" },
    
    { pos: { left: "68px", top: "591px" }, chord: "3" },
    { pos: { left: "198px", top: "591px" }, chord: "4" },
    { pos: { left: "331px", top: "591px" }, chord: "5" },
    { pos: { left: "441px", top: "591px" }, chord: "6" },

    { pos: { left: "68px", top: "676px" }, chord: "3" },
    { pos: { left: "196px", top: "676px" }, chord: "4" },
    { pos: { left: "335px", top: "676px" }, chord: "5" },
    { pos: { left: "450px", top: "676px" }, chord: "1" },
  ]
}

const songdata19 =
{
  id: 19,
  title: "주와 같이 길가는 것",
  rootkey: "E",
  artist: "-",
  tempo: "120, (4/4)",
  score: song19,
  chordArray: [
    { pos: { left: "82px", top: "125px" }, chord: "1" },
    { pos: { left: "151px", top: "125px" }, chord: "3" },
    { pos: { left: "202px", top: "125px" }, chord: "4" },
    { pos: { left: "296px", top: "125px" }, chord: "6" },
    { pos: { left: "394px", top: "125px" }, chord: "5" },

    { pos: { left: "70px", top: "225px" }, chord: "3" },
    { pos: { left: "180px", top: "225px" }, chord: "4" },
    { pos: { left: "284px", top: "225px" }, chord: "2" },
    { pos: { left: "405px", top: "225px" }, chord: "5" },
    { pos: { left: "87px", top: "320px" }, chord: "1" },

    { pos: { left: "71px", top: "393px" }, chord: "4" },
    { pos: { left: "182px", top: "393px" }, chord: "3" },
    { pos: { left: "289px", top: "393px" }, chord: "4" },
    { pos: { left: "400px", top: "393px" }, chord: "3" },

    { pos: { left: "72px", top: "460px" }, chord: "7b" },
    { pos: { left: "186px", top: "460px" }, chord: "4/6" },
    { pos: { left: "300px", top: "460px" }, chord: "2" },
    { pos: { left: "400px", top: "460px" }, chord: "5" },

    { pos: { left: "74px", top: "616px" }, chord: "1" },
    { pos: { left: "150px", top: "616px" }, chord: "4" },
    { pos: { left: "216px", top: "616px" }, chord: "6" },
    { pos: { left: "288px", top: "616px" }, chord: "5" },

    { pos: { left: "348px", top: "616px" }, chord: "3" },
    { pos: { left: "428px", top: "616px" }, chord: "4" },
    { pos: { left: "74px", top: "697px" }, chord: "2" },
    { pos: { left: "133px", top: "697px" }, chord: "5" },
    { pos: { left: "209px", top: "697px" }, chord: "5" },
    { pos: { left: "256px", top: "697px" }, chord: "1" },
  ]
}

const songdata20 =
{
  id: 20,
  title: "밤이나 낮이나",
  rootkey: "E",
  artist: "-",
  tempo: "60, (4/4)",
  score: song20,
  chordArray: [
    { pos: { left: "67px", top: "49px" }, chord: "1" },
  ]
}
/////  EXPORT DEFAULT SongList

const songList = [
  songdata00,
  songdata01,
  songdata02,
  songdata03,
  songdata04,
  songdata05,
  songdata06,
  songdata07,
  songdata08,
  songdata09,
  songdata10,
  songdata11,
  songdata12,
  songdata13,
  songdata14,
  songdata15,
  songdata16,
  songdata17,
  songdata18,
  songdata19,
  songdata20,
]

export default songList